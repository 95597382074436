<template>
    <div>
        <div v-if="component_done_loading && pageType == 'page'">
            <dark-topbar :test-obj="test" />
        </div>
        <div v-if="component_done_loading" class="col-xs-12" :class="{ padding70px25px: pageType == 'page' }">
            <div v-if="pageType == 'page'" class="nav-tabsContainer">
                <div class="links">
                    <ul>
                        <li :class="{ active: tab == 'leaderboard' }" @click="tab = 'leaderboard'">
                            <a class="nav-link uppercase" data-toggle="tab" href="#leaderboard"> Leaderboard </a>
                        </li>
                        <li v-if="test.settings.allowTeamClarifications" :class="{ active: tab == 'clarification' }" @click="tab = 'clarification'">
                            <a class="nav-link uppercase" data-toggle="tab" href="#clarifications"> Clarifications </a>
                        </li>
                        <li :class="{ active: tab == 'question' }" @click="tab = 'question'">
                            <a class="nav-link uppercase" data-toggle="tab" href="#question"> Questions </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row" :class="{ 'tab-content': pageType == 'page' }">
                <div v-if="tab == 'leaderboard' && test.leaderboard" id="leaderboard" :class="{ active: tab == 'leaderboard' }" class="tab-pane fade in">
                    <clarification-timer-progress :test-obj="test" />
                </div>

                <div
                    v-if="(tab == 'clarification' || pageType != 'page') && test.course"
                    id="clarifications"
                    :class="{
                        'tab-pane fade in': pageType == 'page',
                        active: tab == 'clarification' && pageType == 'page',
                    }"
                >
                    <div v-if="['stopped'].includes(test.clarificationStatus) && set_up_step == 1" class="alert alert-warning marginBottom30">
                        <i class="fa fa-info-circle marginRight8" aria-hidden="true" />Please select clarifications you want to discuss with your students.
                    </div>
                    <kr-panel v-if="test.settings.allowTeamClarifications" :with-footer="false">
                        <template #title>
                            <div class="flex gap10 align-items">
                                <h2>
                                    <template v-if="!['stopped', 'released'].includes(test.clarificationStatus) && set_up_step == 2">
                                        All Clarifying Questions ({{ test.clarifications.questions.length + test.clarifications.freestyles.length }})
                                    </template>
                                    <template v-else>
                                        <template v-if="test.clarificationStatus == 'stopped'">
                                            Selected Clarifying Questions ({{ selected_requests_total }})
                                        </template>
                                        <template v-else-if="test.clarificationStatus == 'released'">
                                            All Clarifying Questions ({{ selected_requests_total }})
                                        </template>
                                        <template v-else> Clarifications </template>
                                    </template>
                                </h2>

                                <button
                                    v-if="['ongoing', 'paused', 'ended', 'completed'].includes(test.status) && pageType == 'form'"
                                    class="btn btn-default"
                                    @click.prevent="clarificationGoTo"
                                >
                                    <i class="fas fa-external-link-alt marginRight8" aria-hidden="true" />Presentation Mode
                                </button>
                            </div>
                        </template>
                        <template #content>
                            <div class="marginBottom20 flex gap10">
                                <div
                                    v-if="['not started', null].includes(test.clarificationStatus)"
                                    class="btn"
                                    :class="{
                                        'p-0-i': hasWritePrivilege(test.course),
                                    }"
                                >
                                    <button
                                        v-tooltip="{
                                            content: hasWritePrivilege(test.course) == -1 ? readOnlyLabel : '',
                                        }"
                                        class="btn btn-success margin0 flexInherit"
                                        :disabled="hasWritePrivilege(test.course) == -1 ? true : undefined"
                                        aria-label="Start Clarification"
                                        @click="start()"
                                    >
                                        <i class="fa fa-caret-right marginRight8" aria-hidden="true" />Start Clarification
                                    </button>
                                </div>
                                <button v-if="['paused'].includes(test.clarificationStatus)" class="btn btn-success margin0 flexInherit" @click="resume()">
                                    <i class="fa fa-caret-right marginRight8" aria-hidden="true" />Resume Clarification
                                </button>
                                <button
                                    v-if="['ongoing', 'paused'].includes(test.clarificationStatus)"
                                    class="btn btn-danger margin0 flexInherit"
                                    @click="end()"
                                >
                                    <i class="fa fa-stop marginRight8" aria-hidden="true" />Stop Clarification
                                </button>
                                <button
                                    v-if="['stopped', 'released'].includes(test.clarificationStatus)"
                                    class="btn btn-danger margin0 flexInherit"
                                    @click="start()"
                                >
                                    <i class="fa fa-refresh marginRight8" aria-hidden="true" />Restart Clarification
                                </button>
                                <button
                                    v-if="['stopped'].includes(test.clarificationStatus) && set_up_step == 1"
                                    class="btn btn-primary margin0 flexInherit"
                                    :disabled="selected_requests_total == 0 ? true : undefined"
                                    @click="confirmRequests()"
                                >
                                    Confirm Selected Requests ({{ selected_requests_total }})
                                </button>
                                <button
                                    v-if="['stopped'].includes(test.clarificationStatus) && set_up_step == 2"
                                    class="btn btn-primary margin0 flexInherit"
                                    @click="release()"
                                >
                                    <div class="fa-stack corneredLeft marginRight8">
                                        <i class="fa fa-users fa-stack-2x" aria-hidden="true" />
                                        <i class="fa fa-arrow-right fa-stack-1x" aria-hidden="true" />
                                    </div>
                                    Release Team Assignments
                                </button>

                                <template v-if="['stopped'].includes(test.clarificationStatus) && set_up_step == 2">
                                    <button class="btn btn-default margin0 flexInherit" @click="set_up_step = 1">
                                        <i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back to Request Selection
                                    </button>
                                </template>

                                <template v-if="pageType == 'page'">
                                    <template v-if="['stopped'].includes(test.clarificationStatus) && set_up_step == 2">
                                        <router-link
                                            v-slot="{ navigate }"
                                            :to="{
                                                name: 'activities.tests.dashboard',
                                                params: {
                                                    id: $route.params.id,
                                                },
                                            }"
                                            custom
                                        >
                                            <button class="btn btn-default margin0 flexInherit" @click="navigate">
                                                <i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back to Dashboard
                                            </button>
                                        </router-link>
                                    </template>
                                    <template v-else>
                                        <router-link
                                            v-slot="{ navigate }"
                                            :to="{
                                                name: 'activities.tests.dashboard',
                                                params: {
                                                    id: $route.params.id,
                                                },
                                            }"
                                            custom
                                        >
                                            <button class="btn btn-default margin0 flexInherit" @click="navigate">
                                                <i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back to Dashboard
                                            </button>
                                        </router-link>
                                    </template>
                                </template>
                            </div>

                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th v-if="test.clarificationStatus == 'stopped' && set_up_step == 1" class="numColumn">Select Question</th>
                                            <th style="width: 35%">Areas That Need Clarification</th>
                                            <th>No. Of Request</th>
                                            <th v-if="(test.clarificationStatus == 'stopped' && set_up_step == 2) || test.clarificationStatus == 'released'">
                                                Assign Team(s) To Explain
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-if="clarifications.length == 0">
                                            <tr>
                                                <td colspan="5" style="text-align: center">No Clarification Submitted</td>
                                            </tr>
                                        </template>
                                        <template v-for="(question, question_idx) in clarifications">
                                            <tr
                                                v-if="
                                                    !['stopped', 'released'].includes(test.clarificationStatus) ||
                                                    (test.clarificationStatus == 'stopped' && (question.isDisplayed || set_up_step != 2)) ||
                                                    (test.clarificationStatus == 'released' && question.isDisplayed)
                                                "
                                            >
                                                <td v-if="test.clarificationStatus == 'stopped' && set_up_step == 1" class="textAlignCenter">
                                                    <div class="checkboxField d-inlineBlock">
                                                        <input
                                                            :id="'clarification_question' + question.question.number"
                                                            v-model="clarifications[question_idx].isDisplayed"
                                                            type="checkbox"
                                                            class="checkbox-field"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <template v-if="question._type == 'question'">
                                                        <button
                                                            class="btn gradient btn-default marginBottom20"
                                                            :aria-label="`Question ${question.question.number}`"
                                                            @click.prevent="openQuestionInfoModal(question)"
                                                        >
                                                            <i class="fa fa-info-circle marginRight8" aria-hidden="true" />Question
                                                            {{ question.question.number }}
                                                        </button>

                                                        <label :for="'clarification_question' + question.question.number" class="width100">
                                                            Question {{ question.question.number }}
                                                        </label>
                                                        <div
                                                            v-if="question.question.questionIsHTML"
                                                            class="marginTop10 whiteSpaceBreakSpacesText"
                                                            v-html="question.question.question"
                                                        />
                                                        <div v-else class="marginTop10 whiteSpaceBreakSpacesText">
                                                            {{ question.question.question }}
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <div>
                                                            <span class="btn gradient btn-default"> Freestyle </span>
                                                            <div class="marginTop20 whiteSpaceBreakSpacesText">
                                                                {{ question.freestyle }}
                                                            </div>
                                                        </div>
                                                    </template>
                                                </td>
                                                <td>
                                                    <template v-if="question._type == 'question'">
                                                        <template v-for="(request, request_idx) in question.requests">
                                                            <div class="marginBottom20">
                                                                <div
                                                                    class="col-xs-6"
                                                                    :class="{
                                                                        buttonGroup: request.attachments && request.attachments.length != 0,
                                                                    }"
                                                                >
                                                                    <button
                                                                        class="btn gradient btn-default"
                                                                        :aria-label="request.team.name"
                                                                        @click.prevent="openTeamInfoModal(request.team)"
                                                                    >
                                                                        {{ request.team.name }}
                                                                    </button>
                                                                    <button
                                                                        v-if="request.attachments && request.attachments.length != 0"
                                                                        class="btn gradient btn-default"
                                                                        aria-label="Team's Attachments"
                                                                        @click.prevent="
                                                                            openTeamAttachmentsModal({
                                                                                name: request.team.name,
                                                                                attachments: request.attachments,
                                                                            })
                                                                        "
                                                                    >
                                                                        <i class="fa fa-paperclip marginRight8" aria-hidden="true" />Team’s Attachment(s)
                                                                    </button>
                                                                </div>
                                                                <div
                                                                    class="marginTop10 whiteSpaceBreakSpacesText"
                                                                    v-html="filterProfanity(request.clarifications)"
                                                                />
                                                            </div>
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        <div class="marginBottom20">
                                                            <div
                                                                class="col-xs-6"
                                                                :class="{
                                                                    buttonGroup: question.attachments && question.attachments.length != 0,
                                                                }"
                                                            >
                                                                <button
                                                                    class="btn gradient btn-default"
                                                                    :aria-label="question.requestedBy.name"
                                                                    @click.prevent="openTeamInfoModal(question.requestedBy)"
                                                                >
                                                                    {{ question.requestedBy.name }}
                                                                </button>
                                                                <button
                                                                    v-if="question.attachments && question.attachments.length != 0"
                                                                    class="btn gradient btn-default"
                                                                    aria-label="Team's Attachments"
                                                                    @click.prevent="
                                                                        openTeamAttachmentsModal({
                                                                            name: question.requestedBy.name,
                                                                            attachments: question.attachments,
                                                                        })
                                                                    "
                                                                >
                                                                    <i class="fa fa-paperclip marginRight8" aria-hidden="true" />Team’s Attachment(s)
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <template v-for="(team, team_idx) in question.votedBy">
                                                            <div class="marginTop20">
                                                                <button
                                                                    class="btn gradient btn-default"
                                                                    :aria-label="`${team.name}(Voted)`"
                                                                    @click.prevent="openTeamInfoModal(team)"
                                                                >
                                                                    {{ team.name }}(Voted)
                                                                </button>
                                                            </div>
                                                        </template>
                                                    </template>
                                                </td>
                                                <td
                                                    v-if="(test.clarificationStatus == 'stopped' && set_up_step == 2) || test.clarificationStatus == 'released'"
                                                >
                                                    <div v-if="question.assignedTo.length >= 1 && question._edit != true" class="multiselect padding10">
                                                        <button
                                                            v-if="test.clarificationStatus != 'released'"
                                                            class="btn btn-default"
                                                            @click.prevent="
                                                                question._edit = true;
                                                                clarifications.splice();
                                                            "
                                                        >
                                                            <i class="fa fa-edit marginRight8" aria-hidden="true" />Edit
                                                        </button>
                                                        <button
                                                            v-for="(team, team_idx) in question.assignedTo"
                                                            class="btn gradient btn-default marginLeft0 marginTop10"
                                                            @click.prevent="openTeamInfoModal(team)"
                                                        >
                                                            <i class="fa fa-users marginRight8" aria-hidden="true" />
                                                            {{ team.name }}
                                                        </button>
                                                    </div>
                                                    <div v-else class="multiselect">
                                                        <button
                                                            class="btn dropdown-toggle flexSpaceBetween align-items width100"
                                                            @click.prevent="toggleOptions(question.uuid)"
                                                        >
                                                            Please select team(s)
                                                            <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                                                        </button>

                                                        <div class="multiSelectPanel">
                                                            <div :id="'checkboxes_' + question.uuid" class="checkboxes">
                                                                <template v-for="(team, team_idx) in question.assignedTo">
                                                                    <div class="checkboxField flex align-items">
                                                                        <input
                                                                            :id="'assign_checkbox_' + question_idx + '_' + team.uuid"
                                                                            v-model="question._assignedTo"
                                                                            class="checkbox-field"
                                                                            type="checkbox"
                                                                            :value="team.uuid"
                                                                            :aria-label="team.name"
                                                                        />
                                                                        <label :for="'assign_checkbox_' + question_idx + '_' + team.uuid">
                                                                            <span>
                                                                                {{ team.name }}
                                                                                <template v-if="question._type == 'question'">
                                                                                    ({{ round(team.percent) }}%)
                                                                                </template>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </template>
                                                                <template v-for="(team, team_idx) in question.unassignedTeams">
                                                                    <div class="checkboxField flex align-items">
                                                                        <input
                                                                            :id="'assign_checkbox_' + question_idx + '_' + team.uuid"
                                                                            v-model="question._assignedTo"
                                                                            class="checkbox-field"
                                                                            type="checkbox"
                                                                            :value="team.uuid"
                                                                            :aria-label="team.name"
                                                                        />
                                                                        <label :for="'assign_checkbox_' + question_idx + '_' + team.uuid">
                                                                            <span>
                                                                                {{ team.name }}
                                                                                <template v-if="question._type == 'question'">
                                                                                    ({{ round(team.percent) }}%)
                                                                                </template>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </template>
                                                            </div>
                                                            <div :id="'footer_' + question.uuid" class="footer flexRight">
                                                                <div class="buttonGroup">
                                                                    <button
                                                                        class="btn"
                                                                        aria-label="Cancel"
                                                                        @click.prevent="clarifications[question_idx]._edit = false"
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                    <button
                                                                        class="btn btn-primary"
                                                                        aria-label="Done"
                                                                        @click.prevent="saveTeamAssignments(question)"
                                                                    >
                                                                        Done
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>

                            <div class="marginTop20 flex gap10">
                                <div
                                    v-if="['not started', null].includes(test.clarificationStatus)"
                                    v-tooltip="{
                                        content: hasWritePrivilege(test.course) == -1 ? readOnlyLabel : '',
                                    }"
                                    class="btn"
                                    :class="{
                                        'p-0-i': hasWritePrivilege(test.course),
                                    }"
                                >
                                    <button
                                        class="btn btn-success margin0"
                                        :disabled="hasWritePrivilege(test.course) == -1 ? true : undefined"
                                        aria-label="Start Clarification"
                                        @click="start()"
                                    >
                                        <i class="fa fa-caret-right marginRight8" aria-hidden="true" />Start Clarification
                                    </button>
                                </div>
                                <button
                                    v-if="['paused'].includes(test.clarificationStatus)"
                                    class="btn btn-success margin0"
                                    aria-label="Resume Clarification"
                                    @click="resume()"
                                >
                                    <i class="fa fa-caret-right marginRight8" aria-hidden="true" />Resume Clarification
                                </button>
                                <button
                                    v-if="['ongoing', 'paused'].includes(test.clarificationStatus)"
                                    class="btn btn-danger margin0"
                                    aria-label="Stop Clarification"
                                    @click="end()"
                                >
                                    <i class="fa fa-stop marginRight8" aria-hidden="true" />Stop Clarification
                                </button>
                                <button
                                    v-if="['stopped', 'released'].includes(test.clarificationStatus)"
                                    class="btn btn-danger margin0"
                                    aria-label="Restart Clarification"
                                    @click="start()"
                                >
                                    <i class="fa fa-refresh marginRight8" aria-hidden="true" />Restart Clarification
                                </button>
                                <button
                                    v-if="['stopped'].includes(test.clarificationStatus) && set_up_step == 1"
                                    class="btn btn-primary margin0"
                                    :disabled="selected_requests_total == 0 ? true : undefined"
                                    :aria-label="'Confirm Selected Requests (' + selected_requests_total + ')'"
                                    @click="confirmRequests()"
                                >
                                    Confirm Selected Requests ({{ selected_requests_total }})
                                </button>
                                <button
                                    v-if="['stopped'].includes(test.clarificationStatus) && set_up_step == 2"
                                    class="btn btn-primary margin0"
                                    aria-label="Release Team Assignments"
                                    @click="release()"
                                >
                                    <div class="fa-stack corneredLeft marginRight8">
                                        <i class="fa fa-users fa-stack-2x" aria-hidden="true" />
                                        <i class="fa fa-arrow-right fa-stack-1x" aria-hidden="true" />
                                    </div>
                                    Release Team Assignments
                                </button>

                                <template v-if="['stopped'].includes(test.clarificationStatus) && set_up_step == 2">
                                    <button class="btn btn-default margin0" aria-label="BAck to Request Selection" @click="set_up_step = 1">
                                        <i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back to Request Selection
                                    </button>
                                </template>

                                <template v-if="pageType == 'page'">
                                    <template v-if="['stopped'].includes(test.clarificationStatus) && set_up_step == 2">
                                        <router-link
                                            v-slot="{ navigate }"
                                            :to="{
                                                name: 'activities.tests.dashboard',
                                                params: {
                                                    id: $route.params.id,
                                                },
                                            }"
                                            custom
                                        >
                                            <button class="btn btn-default margin0" @click="navigate">
                                                <i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back to Dashboard
                                            </button>
                                        </router-link>
                                    </template>
                                    <template v-else>
                                        <router-link
                                            v-slot="{ navigate }"
                                            :to="{
                                                name: 'activities.tests.dashboard',
                                                params: {
                                                    id: $route.params.id,
                                                },
                                            }"
                                            custom
                                        >
                                            <button class="btn btn-default margin0" @click="navigate">
                                                <i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back to Dashboard
                                            </button>
                                        </router-link>
                                    </template>
                                </template>
                            </div>
                        </template>
                    </kr-panel>
                </div>
                <div v-if="tab == 'question' && test.questions" id="question" :class="{ active: tab == 'question' }" class="tab-pane fade in">
                    <activity-questions-div :test-obj="test" :options="{ showWindowLink: false }" />
                </div>
            </div>
            <div id="clarificationQuestionInfoModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="clarificationQuestionInfoModal-title">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                                <i class="fa-solid fa-xmark" aria-hidden="true" />
                            </button>

                            <h2 id="clarificationQuestionInfoModal-title" class="modal-title">View Question</h2>
                        </div>
                        <div class="modal-body">
                            <question-display :test-obj="test" :question="previewQuestion" />
                            <div class="col-xs-12 buttonGroup marginTop30">
                                <button class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                            </div>
                        </div>
                        <div class="modal-footer"></div>
                    </div>
                </div>
            </div>
            <div id="clarificationTeamInfoModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="clarificationTeamInfoModal-title">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                                <i class="fa-solid fa-xmark" aria-hidden="true" />
                            </button>

                            <h2 id="clarificationTeamInfoModal-title" class="modal-title">View {{ previewTeam.name }}</h2>
                        </div>
                        <div class="modal-body">
                            <div class="col-xs-12">
                                <ul v-for="(student, idx) in previewTeam.members">
                                    <li>{{ student }}</li>
                                </ul>
                            </div>
                            <div class="col-xs-12 marginTop20 buttonGroup">
                                <button class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                            </div>
                        </div>
                        <div class="modal-footer" />
                    </div>
                </div>
            </div>
            <div id="clarificationTeamAttachmentsModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="clarificationTeamAttachmentsModal-title">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                                <i class="fa-solid fa-xmark" aria-hidden="true" />
                            </button>

                            <h2 id="clarificationTeamAttachmentsModal-title" class="modal-title">{{ previewTeam.name }} Attachments</h2>
                        </div>
                        <div class="modal-body">
                            <div class="col-xs-12 col-md-6">
                                <div v-if="previewTeam.attachments && previewTeam.attachments.length != 0" class="flex align-items">
                                    <div class="col-xs-12">
                                        <div v-for="(file, idx) in previewTeam.attachments" class="flexSpaceBetween">
                                            <div>
                                                <span>{{ idx + 1 }}.</span>
                                                <span>
                                                    <i class="fa fa-file-image-o" />
                                                </span>
                                                <a :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <template v-else>
                                    <div>-</div>
                                </template>
                            </div>
                            <div class="col-xs-12 buttonGroup marginTop20">
                                <button class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                            </div>
                        </div>
                        <div class="modal-footer" />
                    </div>
                </div>
            </div>
            <div
                id="clarificationRequestMoreInfoModal"
                class="modal default-modal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="clarificationRequestMoreInfoModal-title"
            >
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                                <i class="fa-solid fa-xmark" aria-hidden="true" />
                            </button>

                            <h2 id="clarificationRequestMoreInfoModal-title" class="modal-title">Request Information</h2>
                        </div>
                        <div v-if="previewRequest._type" class="modal-body">
                            <template v-if="previewRequest._type == 'question'">
                                <button class="btn gradient btn-default-border-primary" :aria-label="`${previewRequest.requests.length} Teams`">
                                    {{ previewRequest.requests.length }} Teams
                                </button>
                                <template v-for="(request, request_idx) in previewRequest.requests">
                                    <div class="marginTop20">
                                        <div>
                                            <button
                                                class="btn gradient btn-default"
                                                data-dismiss="modal"
                                                :aria-label="request.team.name"
                                                @click.prevent="openTeamInfoModal(request.team)"
                                            >
                                                {{ request.team.name }}
                                            </button>
                                        </div>
                                        <div class="marginTop10 whiteSpaceBreakSpacesText" v-html="filterProfanity(request.clarifications)" />
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <button class="btn gradient btn-default-border-primary">
                                    {{ previewRequest.votedBy.length + 1 }}
                                    TEAMS
                                </button>
                                <div class="marginTop20">
                                    <div>
                                        <button
                                            class="btn gradient btn-default flex1"
                                            data-dismiss="modal"
                                            :aria-label="previewRequest.requestedBy.name"
                                            @click.prevent="openTeamInfoModal(previewRequest.requestedBy)"
                                        >
                                            {{ previewRequest.requestedBy.name }}
                                        </button>
                                    </div>
                                </div>
                                <template v-for="(team, team_idx) in previewRequest.votedBy">
                                    <div class="marginTop20">
                                        <button
                                            class="btn gradient btn-default"
                                            data-dismiss="modal"
                                            :aria-label="`${team.name}(Voted)`"
                                            @click.prevent="openTeamInfoModal(team)"
                                        >
                                            {{ team.name }}(Voted)
                                        </button>
                                    </div>
                                </template>
                            </template>
                        </div>

                        <div class="modal-footer">
                            <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrAuth from '../../../../../../../../../components/auth/auth';
export default {
    props: ['options'],
    data() {
        let auth = new KrAuth();
        return {
            component_done_loading: false,
            test: {},
            question: {},
            clarifications: [],
            set_up_step: 1,
            previewQuestion: {},
            previewTeam: {},
            previewRequest: {},
            tab: 'clarification',
            pageType: 'page',
            auth: auth,
            readOnlyLabel: 'You have read-only access',
            debouncedUpdate: null,
        };
    },
    mounted() {
        var that = this;
        this.fetchTest().then(function (response) {
            let a = {};
            _.merge(a, that.test, response.data.data);
            that.test = a;
            if (that.tab == 'clarification') {
                that.init();
            }
            that.processEchoListeners();
            that.component_done_loading = true;
            Vue.nextTick(function () {
                $('#clarificationQuestionInfoModal .modal-dialog').draggable({
                    handle: '.modal-header',
                });
                $('#clarificationTeamInfoModal .modal-dialog').draggable({
                    handle: '.modal-header',
                });
                $('#clarificationTeamAttachmentsModal .modal-dialog').draggable({
                    handle: '.modal-header',
                });
                $('#clarificationRequestMoreInfoModal .modal-dialog').draggable({
                    handle: '.modal-header',
                });
            });
        });
    },
    created() {
        var that = this;
        this.tab = _.get(this, '$route.query.tab', 'clarification');
        this.pageType = _.get(this, 'options.pageType', 'page');
        if (this.pageType == 'page') {
            $('body').addClass('test nav-none').removeClass('nav-sm');
        }
        Events.fire('topbar_update', {
            title: '<i class="fas fa-pencil-ruler"></i>&nbsp;Activity',
        });
        Events.listen('changed-test', function (data) {
            //fake automatic
            that.test = data;
            that.init();
        });
        this.debouncedUpdate = _.debounce(function () {
            that.fetchTest({ _internal: { hasLoadingBar: false } }).then(function (response) {
                let a = {};
                let b = response.data.data.clarifications;
                _.merge(a, that.test, response.data.data);
                a.clarifications = b;
                that.test = a;
                if (that.tab == 'clarification') {
                    that.init();
                }
            });
        });
    },
    methods: {
        processEchoListeners() {
            var that = this;
            let h1 = (e) => {
                if (e.test.uuid != that.test.uuid) {
                    return false;
                }
                this.debouncedUpdate();
            };
            let c1 = window.Echo.private(`course.${this.test.course.uuid}.teacher`).listen('TestStatusUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'TestStatusUpdated',
                handler: h1,
            });

            let h2 = (e) => {
                this.debouncedUpdate();
            };
            let c2 = window.Echo.private(`test.${this.test.uuid}.teacher`).listen('ClarificationSaved', h2);
            this.echoChannels.push({
                channel: c2,
                event: 'ClarificationSaved',
                handler: h2,
            });

            let h3 = (e) => {
                this.test.clarificationStatus = e.clarificationStatus;
            };
            let c3 = window.Echo.private(`test.${this.test.uuid}.teacher`).listen('TestClarificationStatusUpdated', h3);
            this.echoChannels.push({
                channel: c3,
                event: 'TestClarificationStatusUpdated',
                handler: h3,
            });

            let h4 = (e) => {
                this.debouncedUpdate();
            };
            let c4 = window.Echo.private(`test.${this.test.uuid}.teacher`).listen('ClarificationFreestyleDeleted', h4);
            this.echoChannels.push({
                channel: c4,
                event: 'ClarificationFreestyleDeleted',
                handler: h4,
            });

            let h5 = (e) => {
                this.debouncedUpdate();
            };
            let c5 = window.Echo.private(`test.${this.test.uuid}.teacher`).listen('ClarificationFreestyleSaved', h5);
            this.echoChannels.push({
                channel: c5,
                event: 'ClarificationFreestyleSaved',
                handler: h5,
            });

            /*let h6 = (e) => {
				if(this.test.questions instanceof Array){
           	 		for(var i = 0; i <this.test.questions.length;i++){
	                    for(var j = 0; j<this.test.questions[i].length;j++){
	                    	if(this.test.questions[i][j].uuid==e.activityQuestion.uuid){
	                    	 	this.test.questions[i][j].displayAnswer = e.activityQuestion.displayAnswer?true:false;
	                    	 	this.test.questions[i][j].displayAnswerStatistics = e.activityQuestion.displayAnswerStatistics?true:false;
	                    	}
	                    }
	                }
           	 	}
			}
			let c6= window.Echo.private(`test.${this.test.uuid}.teacher`).listen('DiscussionQuestionStatusUpdated', h6 );
			this.echoChannels.push({channel:c6,event:'DiscussionQuestionStatusUpdated',handler:h6})
			*/
            let h6 = (e) => {
                if (e.test.uuid != that.test.uuid) {
                    return false;
                }
                this.debouncedUpdate();
            };
            let c6 = window.Echo.private(`course.${this.test.course.uuid}.teacher`).listen('TestVisibilityUpdated', h6);
            this.echoChannels.push({
                channel: c6,
                event: 'TestVisibilityUpdated',
                handler: h6,
            });
        },
        openQuestionInfoModal(question) {
            this.previewQuestion = question.question;
            $('#clarificationQuestionInfoModal').modal('show');
        },
        openTeamInfoModal(team) {
            var that = this;
            this.previewTeam = team;
            $('#clarificationTeamInfoModal').modal('show');

            $('#clarificationTeamInfoModal').on('hidden.bs.modal', function () {
                that.previewTeam = {};
            });
        },
        openTeamAttachmentsModal(team) {
            var that = this;
            this.previewTeam = team;
            $('#clarificationTeamAttachmentsModal').modal('show');
            $('#clarificationTeamAttachmentsModal').on('hidden.bs.modal', function () {
                that.previewTeam = {};
            });
        },
        openRequestMoreInfoModal(request) {
            var that = this;
            this.previewRequest = request;
            $('#clarificationRequestMoreInfoModal').modal('show');
            // $('#clarificationRequestMoreInfoModal').on('hidden.bs.modal', function () {
            //     that.previewRequest = {};
            // });
        },
        init() {
            this.clarifications = [];
            let count = 0;
            for (var i = 0; i < this.test.clarifications.questions.length; i++) {
                //this.test.clarifications.questions[i].question_no = ++count;
                this.test.clarifications.questions[i]._edit = false;
                this.test.clarifications.questions[i]._type = 'question';
                this.test.clarifications.questions[i]._assignedTo = [];
                this.test.clarifications.questions[i].uuid = this.test.clarifications.questions[i].question.uuid;
                this.test.clarifications.questions[i].isDisplayed = this.test.clarifications.questions[i].question.isDisplayed;
                for (var j = 0; j < this.test.clarifications.questions[i].assignedTo.length; j++) {
                    this.test.clarifications.questions[i]._assignedTo.push(this.test.clarifications.questions[i].assignedTo[j].uuid);
                }

                this.clarifications.push(_.clone(this.test.clarifications.questions[i]));
            }
            for (var i = 0; i < this.test.clarifications.freestyles.length; i++) {
                let freestyle = this.test.clarifications.freestyles[i];
                freestyle._type = 'freestyle';
                freestyle._edit = false;
                freestyle._assignedTo = [];
                for (var j = 0; j < freestyle.assignedTo.length; j++) {
                    freestyle._assignedTo.push(freestyle.uuid);
                }
                this.clarifications.push(_.clone(freestyle));
            }
        },
        clearTeamAssignments() {
            var that = this;
            axios
                .post('/tests/' + this.test.uuid + '/clarifications/clear-assignments')
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'teams has been cleared',
                    });
                    for (var i = 0; i < that.clarifications.length; i++) {
                        let question = that.clarifications[i];
                        for (var j = question.assignedTo.length - 1; j >= 0; j--) {
                            question.unassignedTeams.push(question.assignedTo[j]);
                            question.assignedTo.splice(j, 1);
                        }
                        question._edit = false;
                    }
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to clear teams for clarifications at this moment, please try again later',
                    });
                });
        },
        saveTeamAssignments(question) {
            var that = this;
            let url = '/tests/' + this.test.uuid + '/clarifications/assign';
            let data = {
                activityQuestionUuid: question.uuid,
                teamUuids: question._assignedTo,
            };

            if (question._type == 'freestyle') {
                url = '/tests/' + this.test.uuid + '/clarifications/assign-freestyle';
                data = {
                    freestyleUuid: question.uuid,
                    teamUuids: question._assignedTo,
                };
            }
            axios
                .post(url, data)
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Clarification teams has been updated',
                    });
                    //adding
                    for (var i = question.unassignedTeams.length - 1; i >= 0; i--) {
                        if (question._assignedTo.includes(question.unassignedTeams[i].uuid)) {
                            question.assignedTo.push(question.unassignedTeams[i]);
                            question.unassignedTeams.splice(i, 1);
                        }
                    }
                    //removing
                    for (var i = question.assignedTo.length - 1; i >= 0; i--) {
                        if (!question._assignedTo.includes(question.assignedTo[i].uuid)) {
                            question.unassignedTeams.push(question.assignedTo[i]);
                            question.assignedTo.splice(i, 1);
                        }
                    }
                    question._edit = false;
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to update clarification teams at this moment, please try again later',
                    });
                });
        },
        toggleOptions(idx, type = 'desktop') {
            //var checkboxes = document.getElementById("checkboxes_"+idx);
            if (type == 'desktop') {
                $('#checkboxes_' + idx).toggle();
                $('#footer_' + idx).toggle();
            } else {
                $('#m_checkboxes_' + idx).toggle();
                $('#m_footer_' + idx).toggle();
            }
        },
        confirmRequests() {
            var that = this;
            let activityQuestionUuids = [];
            let freestyleUuids = [];
            for (var i = 0; i < this.clarifications.length; i++) {
                if (this.clarifications[i].isDisplayed) {
                    if (this.clarifications[i]._type == 'question') {
                        activityQuestionUuids.push(this.clarifications[i].uuid);
                    } else {
                        freestyleUuids.push(this.clarifications[i].uuid);
                    }
                }
            }
            axios
                .post('/tests/' + this.test.uuid + '/clarifications/display', {
                    activityQuestionUuids: activityQuestionUuids,
                    freestyleUuids: freestyleUuids,
                })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Successfully confirmed clarification requests',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to confirm clarifications at this moment, please try again later',
                    });
                });
            this.set_up_step = 2;
        },
        start() {
            var that = this;
            axios
                .post('tests/' + this.test.uuid + '/clarifications/start')
                .then(function (response) {
                    that.test.clarificationStatus = 'ongoing';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Clarification has been started',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to start clarification at this moment, please try again later',
                    });
                });
        },
        pause() {
            var that = this;
            axios
                .post('tests/' + this.test.uuid + '/clarifications/pause')
                .then(function (response) {
                    that.test.clarificationStatus = 'paused';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Clarification has been paused',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to pause clarification at this moment, please try again later',
                    });
                });
        },
        resume() {
            var that = this;
            axios
                .post('tests/' + this.test.uuid + '/clarifications/resume')
                .then(function (response) {
                    that.test.clarificationStatus = 'ongoing';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Clarification has been resumed',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to resume clarification at this moment, please try again later',
                    });
                });
        },
        end() {
            var that = this;
            axios
                .post('tests/' + this.test.uuid + '/clarifications/stop')
                .then(function (response) {
                    that.test.clarificationStatus = 'stopped';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Clarification has been ended',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to end clarification at this moment, please try again later',
                    });
                });
        },
        restart() {
            var that = this;
            this.set_up_step = 1;
            axios
                .post('tests/' + this.test.uuid + '/clarifications/restart')
                .then(function (response) {
                    that.test.clarificationStatus = 'ongoing';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Clarification has been restarted',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to restart clarification at this moment, please try again later',
                    });
                });
        },
        release() {
            var that = this;
            axios
                .post('tests/' + this.test.uuid + '/clarifications/release')
                .then(function (response) {
                    that.test.clarificationStatus = 'released';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Clarification has been released',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to release clarification results at this moment, please try again later',
                    });
                });
        },
        fetchClarifications(options) {
            return axios.get('tests/' + this.$route.params.id + '/presentation/clarifications', options);
        },
        fetchQuestions(options) {
            return axios.get('tests/' + this.$route.params.id + '/presentation/questions', options);
        },
        fetchLeaderboard(options) {
            return axios.get('tests/' + this.$route.params.id + '/presentation/leaderboard', options);
        },
        fetchTest(options) {
            if (this.pageType == 'page') {
                if (this.tab == 'clarification') {
                    return this.fetchClarifications(options);
                } else if (this.tab == 'question') {
                    return this.fetchQuestions(options);
                } else if (this.tab == 'leaderboard') {
                    return this.fetchLeaderboard(options);
                }
            } else {
                return this.fetchClarifications(options);
            }
        },
        clarificationGoTo() {
            let routeData = this.$router.resolve({
                name: 'activities.tests.dashboard.trat-presentation',
                params: { id: this.test.uuid },
            });

            window.open(routeData.href, '_blank');
        },
    },
    components: {
        'question-display': require(`./../../../../../../../questions/partials/question-display.vue`).default,
        'clarification-timer-progress': require(`./clarification-timer-progress.vue`).default,
        'activity-questions-div': require(`./../../partials/questions-div.vue`).default,
        'dark-topbar': require(`./../../../partials/dark-topbar.vue`).default,
    },
    computed: {
        selected_requests_total() {
            let count = 0;
            for (var i = 0; i < this.clarifications.length; i++) {
                if (this.clarifications[i].isDisplayed) {
                    count++;
                }
            }
            return count;
        },
    },
    watch: {
        tab: {
            handler: function () {
                var that = this;
                this.fetchTest().then(function (response) {
                    let a = {};
                    _.merge(a, that.test, response.data.data);
                    that.test = a;
                    if (that.tab == 'clarification') {
                        that.init();
                    }
                    that.$nextTick(function () {
                        that.$forceUpdate();
                    });
                });
            },
            deep: true,
        },
    },
    beforeUnmount() {
        if (this.pageType == 'page') {
            $('body').removeClass('test nav-none').addClass('nav-sm');
        }
    },
};
</script>
<style scoped>
.multiselect {
    width: 200px;
}

.multiSelectPanel .checkboxes {
    display: none;
    border: 1px #dadada solid;
    max-height: 100px;
    overflow: auto;
    padding: 15px;
}

.multiSelectPanel .checkboxes label {
    font-size: 1rem;
    color: #222;
}

.multiSelectPanel .checkboxes label span:first-child {
    font-weight: bold;
    font-size: 1rem;
}

.multiSelectPanel .checkboxField {
    margin-top: 15px;
}

.multiSelectPanel .checkboxField:first-child {
    margin-top: 0;
}

.multiSelectPanel {
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
}

.multiSelectPanel .footer {
    display: none;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    border-top: 1px solid #d8d8d8;
    padding: 5px;
}

.multiSelectPanel .footer .btn {
    margin: 0;
}

.multiselect .col-xs-12.btn {
    margin-top: 15px;
}

.multiselect .col-xs-12.btn:first-child {
    margin-top: 0;
}

.corneredLeft span.fa-stack {
    color: #fff;
}

.corneredLeft .fa-stack-2x {
    font-size: 20px;
}

.corneredLeft .fa-stack-1x {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #0071be;
    top: 12px;
    text-align: left;
}

.panel {
    border-radius: 3px;
    border: 1px solid #d8d8d8;
}

.panel .panel-heading {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
}
@media (max-width: 991px) {
    .panel-body .multiselect {
        width: 100%;
    }
}
a .fa-stack,
button .fa-stack {
    width: 18px;
    height: 18px;
    line-height: 1;
}

a .fa-stack-2x,
button .fa-stack-2x {
    font-size: 16px;
}

a .fa-stack-1x,
button .fa-stack-1x {
    font-size: 10px;
    top: 12%;
}
</style>